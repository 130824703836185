import React from 'react';

const account = () => {
  return (
    <div className="container">
      aqui vou ver as coisas que aluguei..
    </div>
  );
};

export default account;