const types = [
  { value: "Tico", label: "Tico" },
  { value: "Martelete", label: "Martelete" },
  { value: "Aspirador de pó", label: "Aspirador de pó" },
  { value: "Extratora", label: "Extratora" },
  { value: "Parafusadeira", label: "Parafusadeira" },
  { value: "Furadeira", label: "Furadeira" },
  { value: "Plaina", label: "Plaina" },
];

export default types;