const category = [
  { value: "Bricolagem", label: "Bricolagem" },
  { value: "Construcao", label: "Construção" },
  { value: "Demolição", label: "Demolição" },
  { value: "Limpeza", label: "Limpeza" },
  { value: "Jardinagem", label: "Jardinagem" },
  { value: "Estrutura", label: "Estrutura" },
  { value: "Corte", label: "Corte" },
  { value: "Perfuração", label: "Perfuração" },
  { value: "Polimento", label: "Polimento" },
  { value: "Kit Hibrido", label: "Kit Híbrido" },
  { value: "Kit Limpeza", label: "Kit Limpeza" },
];

export default category;